export const APP_SETTINGS_DATA = "APP_SETTINGS_DATA";
export const APP_SETTINGS_LOADED = "APP_SETTINGS_LOADED";
export const APP_SETTINGS_ERROR = "APP_SETTINGS_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function appSettingsReducer(state = defaultState, action) {
	switch (action.type) {
		case APP_SETTINGS_DATA:
			return { ...state, data: action.payload };
		case APP_SETTINGS_LOADED:
			return { ...state, loaded: action.payload };
		case APP_SETTINGS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}