import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Toast, Button, Spinner } from "react-bootstrap";

import { setUserInfoData, getUserInfoData } from "../actions/userInfoActions.js";
import CustomModal from "./CustomModal";
import Notification from "./UI/Notification/index.jsx";

export default function AddNewApp(props) {

	const userInfoStore = useSelector(state => state.userInfo);
	const history = useHistory();
	const dispatch = useDispatch();
	const [appName, setAppName] = useState("");
	const [appUrl, setAppUrl] = useState("");
	const [fileId, setFileId] = useState(null);
	const [urlCorrect, setUrlCorrect] = useState(false);
	const [urlChecking, setUrlChecking] = useState(false);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const _user_token = localStorage.getItem("userToken");

	const getAppInfoByURL = async (app_url) => {
		try {
			const response = await (await
				fetch(`/api/v1/frontend/applications/icon_file`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							app_url
						})
					}
				)).json();
			if (response._code === 200) {
				setFileId(response._data.icon_file_id);
				setUrlCorrect(true);
				setUrlChecking(false);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			setUrlChecking(false);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const createApp = async () => {
		try {
			const response = await (await
				fetch(`/api/v1/frontend/applications/create`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							organization_id: userInfoStore.data.selected.organization,
							icon_file_id: fileId,
							name: appName
						})
					}
				)).json();
			if (response._code === 200) {
				dispatch(setUserInfoData({
					selected: {
						organization: userInfoStore.data.selected.organization,
						application: response._data.application_id
					}
				}));
				dispatch(getUserInfoData(`/api/v1/frontend/me/info`, { _user_token }));
				props.handleModal();
				history.push(`/settings/${response._data.application_id}`);
				setUrlCorrect(false);
				setAppName("");
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const handleAppURL = (url) => {
		const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
		if (regex.test(url)) {
			setUrlChecking(true);
			getAppInfoByURL(url);
		} else {
			setUrlCorrect(false);
		}
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	useEffect(() => {
		const timeOutId = setTimeout(() => handleAppURL(appUrl), 500);
		return () => clearTimeout(timeOutId);
	}, [appUrl]);

	return (
		<CustomModal handleModal={props.handleModal} show={props.showModal}>

			<Notification notification={notification} handleNotification={handleNotification} />

			<Container fluid className="px-0">

				<Row className="d-flex justify-content-center pt-4 px-4 m-0">
					<Col className="px-0">
						<h2 className="SFProDisplay-Bold">
							{props.config.wording.text_1}
						</h2>
						<p className="m-0">
							{props.config.wording.text_2}
						</p>
					</Col>
				</Row>

				<Row className="pt-3 px-4 m-0">
					<Col className="px-0">
						<Form.Group>
							<Form.Label className="SFProDisplay-Semibold">
								{props.config.wording.text_3}
							</Form.Label>
							<Form.Control
								type="text"
								onChange={(e) => setAppName(e.target.value)}
								placeholder={props.config.wording.text_4}
							/>
						</Form.Group>
						<Form.Group className="pt-4">
							<Form.Label className="SFProDisplay-Semibold">
								{props.config.wording.text_5}
							</Form.Label>
							<Form.Control
								type="text"
								placeholder={props.config.wording.text_6}
								onChange={(e) => setAppUrl(e.target.value)}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row className="py-4 px-4 m-0">
					<Col xs={"auto"} className="ps-0 pe-2">
						{
							urlChecking
								?
								<Button
									className="rounded-5"
									disabled={!urlCorrect || appName === ""}
								>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									<span className="ps-2">
										{props.config.wording.text_7}
									</span>
								</Button>
								:
								<Button
									className="rounded-5"
									disabled={appName === ""}
									onClick={createApp}
								>
									{props.config.wording.text_8}
								</Button>
						}
					</Col>
					<Col xs={"auto"} className="ps-2 pe-0">
						<Button variant="secondary" className="rounded-5" onClick={props.handleModal}>
							{props.config.wording.text_9}
						</Button>
					</Col>
				</Row>

			</Container>
		</CustomModal>
	);
}