import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { privateRoutes, publicRoutes, allAppsRoutes } from "../router";

import { AppContext } from "../context";
import Authentification from "./Authentification";
import Loader from "./UI/Loader";
import Header from "./Header";
import Navbar from "./Navbar";

export default function AppRouter() {

	const {
		isAuth,
		isLoading,
		allAppsOverviewEnabled
	} = useContext(AppContext);

	if (isLoading) {
		return <Loader />;
	}

	return (
		isAuth
			?
			<Authentification>
				<Container fluid className="px-0">
					<Row className="m-0">
						<Col className="px-0">
							<Header />
						</Col>
					</Row>
					<Row className="m-0">
						<Col className="px-0">
							<Navbar />
						</Col>
					</Row>
					<Row className="m-0">
						<Col className="px-0">
							{allAppsOverviewEnabled ?
								<Switch>
									{allAppsRoutes.map(route =>
										<Route
											component={route.component}
											path={route.path}
											exact={route.exact}
											key={route.path}
										/>
									)}
									<Redirect to="/overview" />
								</Switch>
								:
								<Switch>
									{privateRoutes.map(route =>
										<Route
											component={route.component}
											path={route.path}
											exact={route.exact}
											key={route.path}
										/>
									)}
									<Redirect to="/overview" />
								</Switch>
							}
						</Col>
					</Row>
				</Container>
			</Authentification>
			:
			<Switch>
				{publicRoutes.map(route =>
					<Route
						component={route.component}
						path={route.path}
						exact={route.exact}
						key={route.path}
					/>
				)}
				<Redirect to="/login" />
			</Switch>
	);
}