import { Container, Row, Col, Form, Accordion, Button } from "react-bootstrap";

export default function TeamMembersEdit(props) {

	const handleSelectAllApps = (enabled) => {
		if (enabled) {
			props.handleUserData({ ...props.userData, application_ids: props.appsIds });
		} else {
			props.handleUserData({ ...props.userData, application_ids: [] });
		}
	}

	const handleSelectApp = (id, enabled) => {
		if (enabled) {
			props.handleUserData({
				...props.userData,
				application_ids: [...props.userData.application_ids, id]
			});
		} else {
			props.handleUserData({
				...props.userData,
				application_ids: [...props.userData.application_ids.filter(app => app !== id)]
			});
		}
	}

	return (
		<Container fluid className="px-0">

			<Row className="pt-4 px-4 m-0">
				<Col className="px-0">
					<h3 className="SFProDisplay-Medium m-0">
						{props.config.wording.text_1}
					</h3>
				</Col>
			</Row>

			<Row className="py-3 px-4 m-0">
				<Col className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_2}
						</Form.Label>
						<Form.Control
							type="text"
							defaultValue={props.userData.name}
							onChange={(e) => props.handleUserData({ ...props.userData, name: e.target.value })}
						/>
						<Form.Label className="SFProDisplay-Semibold pt-3">
							{props.config.wording.text_3}
						</Form.Label>
						<Form.Control
							type="text"
							defaultValue={props.userData.email}
							onChange={(e) => props.handleUserData({ ...props.userData, email: e.target.value })}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold pt-3">
							{props.config.wording.text_4}
						</Form.Label>
						<Form.Control
							type="password"
							onChange={(e) => props.handleUserData({ ...props.userData, password: e.target.value })}
						/>
						<Form.Label className="SFProDisplay-Semibold pt-3">
							{props.config.wording.text_5}
						</Form.Label>
						<Form.Control
							type="password"
							onChange={(e) => props.handleUserData({ ...props.userData, password_confirmation: e.target.value })}
						/>
					</Form.Group>
				</Col>
			</Row>

			{props.userRole === 1 &&
				<>
					<Form.Group className="py-3 px-4">
						<Row className="pb-2 m-0">
							<Col xs={12} className="pb-3 px-0">
								<h5 className="SFProDisplay-Medium m-0">
									{props.config.wording.text_6}
								</h5>
							</Col>
							<Col xs="auto" className="px-0">
								<Form.Check
									label={props.config.wording.text_7}
									type="radio"
									name="role"
									defaultChecked={props.userData.role === 1}
									onChange={() => props.handleUserData({ ...props.userData, role: 1 })}
								/>
							</Col>
							<Col className="px-5">
								<Form.Check
									label={props.config.wording.text_8}
									type="radio"
									name="role"
									defaultChecked={props.userData.role === 2}
									onChange={() => props.handleUserData({ ...props.userData, role: 2 })}
								/>
							</Col>
						</Row>
						<Form.Text muted>
							{props.config.wording.text_9}
						</Form.Text>
					</Form.Group>

					<Row className="border-bottom border-1 border-secondary mx-4" />
				</>
			}

			<Row className="py-3 px-4 m-0">
				<Col className="px-0">
					<h5>
						{props.config.wording.text_10}
					</h5>
					<p className="text-dark">
						{props.config.wording.text_11}
					</p>
					{props.userData.role === 1
						?
						<div className="border rounded-3 p-3">
							{props.config.wording.text_15}
						</div>
						:
						<Accordion defaultActiveKey={0}>
							<Accordion.Item>
								<Accordion.Header>{props.config.wording.text_12}</Accordion.Header>
								<Accordion.Body className="px-0" style={{ maxHeight: "200px", overflowY: "scroll" }}>
									<Row className="d-flex align-items-center border-bottom border-1 border-secondary py-2 px-3 m-0">
										<Col xs="auto" className="px-0">
											<Form.Check
												checked={props.userData.role === 1 || props.userData.application_ids.sort().join(',') === props.appsIds.sort().join(',')}
												onChange={(e) => handleSelectAllApps(e.target.checked)}
												disabled={props.userData.role === 1}
											/>
										</Col>
										<Col className="px-3">
											<p className="m-0">{props.config.wording.text_15}</p>
										</Col>
									</Row>
									{props.userData.role !== 1 ? props.applications.map((app, i) => (
										<Row className="d-flex align-items-center pt-2 px-3 m-0" key={i}>
											<Col xs="auto" className="px-0">
												<Form.Check checked={props.userData.application_ids.includes(app.id)} onChange={(e) => handleSelectApp(app.id, e.target.checked)} />
											</Col>
											<Col xs="auto" className="px-3">
												<img src={app.icon_url} width={26} className="rounded-5" alt="app" />
											</Col>
											<Col className="px-0">
												<p className="m-0">{app.name}</p>
											</Col>
										</Row>
									)) : <></>}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					}
				</Col>
			</Row>

			<Row className="d-flex justify-content-end border-top border-1 border-secondary p-3 m-0">
				<Col xs="auto" className="px-2">
					<Button variant="secondary" className="rounded-5" onClick={props.onCancel}>
						{props.config.wording.text_13}
					</Button>
				</Col>
				<Col xs="auto" className="px-2">
					<Button variant="primary" className="rounded-5" onClick={props.onSubmit}>
						{props.config.wording.text_14}
					</Button>
				</Col>
			</Row>

		</Container>
	);
}