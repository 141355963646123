import { NavLink } from "react-router-dom";
import styles from "./Navlink.module.css";

export default function CustomLink({ name, to }) {
	return (
		<NavLink activeClassName={styles.active} className="text-decoration-none text-black" to={to}>
			<div className="py-1 px-3">
				{name}
			</div>
		</NavLink>
	)
}