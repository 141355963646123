import { useEffect } from "react";
import { Pagination } from "react-bootstrap";

export default function CustomPagination(props) {

	let pages = [];
	let start = 1;

	if (props.totalPages > 6 && props.currentPage > 3) {
		if (props.currentPage < props.totalPages - 3) start = props.currentPage - 3;
		else start = props.totalPages - 6;
	}

	for (let page = start; page <= props.totalPages; page++) {
		if (pages.length < 7) pages.push(
			<Pagination.Item key={page} tabIndex={page} active={page === props.currentPage} onClick={() => props.handlePage(page)}>
				{page}
			</Pagination.Item>
		);
		else break;
	}

	useEffect(() => {
		if (props.currentPage > props.totalPages) props.handlePage(props.totalPages);
	}, [props.totalPages])

	return (
		pages.length > 1 &&
		<Pagination>
			<Pagination.Item onClick={() => props.handlePage(props.currentPage - 1)} disabled={props.currentPage === 1}>
				Prev
			</Pagination.Item>

			{(props.totalPages > pages.length && start > 2) &&
				<Pagination.Item onClick={() => props.handlePage(1)}>
					1
				</Pagination.Item>
			}

			{(props.totalPages > pages.length && start > 3) &&
				<Pagination.Item onClick={() => props.handlePage(2)}>
					2
				</Pagination.Item>
			}

			{(props.totalPages > pages.length && start > 1) &&
				<Pagination.Ellipsis onClick={() => props.handlePage(+pages[0].key - 1)} />
			}

			{pages}

			{(props.totalPages > pages.length && start + pages.length <= props.totalPages) &&
				<Pagination.Ellipsis onClick={() => props.handlePage(+pages[pages.length - 1].key + 1)} />
			}

			{(props.totalPages > pages.length && start + 1 + pages.length < props.totalPages) &&
				<Pagination.Item onClick={() => props.handlePage(props.totalPages - 1)}>
					{props.totalPages - 1}
				</Pagination.Item>
			}

			{(props.totalPages > pages.length && start + pages.length < props.totalPages) &&
				<Pagination.Item onClick={() => props.handlePage(props.totalPages)}>
					{props.totalPages}
				</Pagination.Item>
			}

			<Pagination.Item onClick={() => props.handlePage(props.currentPage + 1)} disabled={props.currentPage === props.totalPages}>
				Next
			</Pagination.Item>
		</Pagination>
	);
}