import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ConfigContext } from "../context";
import IntegrationsAmplitude from "../components/IntegrationsAmplitude";
import IntegrationsBranch from "../components/IntegrationsBranch";
import IntegrationsFacebook from "../components/IntegrationsFacebook";
import IntegrationsFirebase from "../components/IntegrationsFirebase";
import Notification from "../components/UI/Notification";
import Loader from "../components/UI/Loader";

export default function IntegrationSettings() {

	const { appConfig } = useContext(ConfigContext);
	const history = useHistory();
	const params = useParams();
	const userInfoStore = useSelector(state => state.userInfo);
	const [integtationData, setIntegrationData] = useState(null);
	const [integrationsSettings, setIntegrationsSettings] = useState({});
	const [eventsSettings, setEventsSettings] = useState({});
	const [typesSettings, setTypesSettings] = useState([]);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const _user_token = localStorage.getItem("userToken");

	const handleIntegrationsSettings = (key, value) => {
		setIntegrationsSettings({ ...integrationsSettings, [key]: value });
	}

	const handleEventsSettings = (key, value) => {
		setEventsSettings({ ...eventsSettings, [key]: value });
	}

	const handleTypesSettings = (data) => {
		if (typesSettings.length > 0)
			setTypesSettings(typesSettings.map(obj => {
				if (obj.id === data.id) return data;
				return obj;
			}));
		else
			setTypesSettings(data);
	}

	const handleSubmitIntegrationSettings = () => {
		if (Object.keys(integrationsSettings).length > 0) {
			submitSettings("set_integration", integrationsSettings);
		}
	}

	const handleSubmitEventsSettings = () => {
		if (Object.keys(eventsSettings).length > 0) {
			submitSettings("set_events", { events: eventsSettings });
		}
	}

	const handleSubmitTypesSettings = () => {
		if (Object.keys(typesSettings).length > 0) {
			submitSettings("set_types", { types: typesSettings });
		}
	}

	const getData = async () => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/integrations/${params.id}/show?${new URLSearchParams({ _user_token, application_id: userInfoStore.data.selected.application }).toString()}`)
			).json();
			if (response._code === 200) {
				if (response._data === "{}") history.push("/integrations");
				setIntegrationData(response._data);
				let events = {};
				response._data.events && Object.keys(response._data.events).map(key => {
					response._data.events[key].map(event => {
						if (event.value) events[event.id] = event.value;
					})
				})
				setEventsSettings(events);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const submitSettings = async (type, data) => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/integrations/${params.id}/${type}`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							application_id: userInfoStore.data.selected.application,
							...data
						})
					})
			).json();
			if (response._code === 200) {
				setNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	useEffect(() => {
		getData();
	}, [userInfoStore.data.selected.application])

	if (params.id === "amplitude") {
		return (
			!integtationData ? <Loader /> :
				<>
					<Notification notification={notification} handleNotification={handleNotification} />
					<IntegrationsAmplitude
						integtationData={integtationData}
						handleIntegrationsSettings={handleIntegrationsSettings}
						handleSubmitIntegrationSettings={handleSubmitIntegrationSettings}
						handleEventsSettings={handleEventsSettings}
						handleSubmitEventsSettings={handleSubmitEventsSettings}
						config={appConfig.Integrations.amplitude}
					/>
				</>
		);
	} else if (params.id === "branch") {
		return (
			!integtationData ? <Loader /> :
				<>
					<Notification notification={notification} handleNotification={handleNotification} />
					<IntegrationsBranch
						integtationData={integtationData}
						handleIntegrationsSettings={handleIntegrationsSettings}
						handleSubmitIntegrationSettings={handleSubmitIntegrationSettings}
						config={appConfig.Integrations.branch}
					/>
				</>
		);
	} else if (params.id === "facebook_pixel") {
		return (
			!integtationData ? <Loader /> :
				<>
					<Notification notification={notification} handleNotification={handleNotification} />
					<IntegrationsFacebook
						integtationData={integtationData}
						handleIntegrationsSettings={handleIntegrationsSettings}
						handleSubmitIntegrationSettings={handleSubmitIntegrationSettings}
						config={appConfig.Integrations.facebook}
					/>
				</>
		);
	} else if (params.id === "firebase") {
		return (
			!integtationData ? <Loader /> :
				<>
					<Notification notification={notification} handleNotification={handleNotification} />
					<IntegrationsFirebase
						integtationData={integtationData}
						handleIntegrationsSettings={handleIntegrationsSettings}
						handleSubmitIntegrationSettings={handleSubmitIntegrationSettings}
						handleTypesSettings={handleTypesSettings}
						handleSubmitTypesSettings={handleSubmitTypesSettings}
						config={appConfig.Integrations.firebase}
					/>
				</>
		);
	} else return <></>
}