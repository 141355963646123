export const INTEGRATION_STATUSES_DATA = "INTEGRATION_STATUSES_DATA";
export const INTEGRATION_STATUSES_LOADED = "INTEGRATION_STATUSES_LOADED";
export const INTEGRATION_STATUSES_ERROR = "INTEGRATION_STATUSES_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function integrationStatusesReducer(state = defaultState, action) {
	switch (action.type) {
		case INTEGRATION_STATUSES_DATA:
			return { ...state, data: action.payload };
		case INTEGRATION_STATUSES_LOADED:
			return { ...state, loaded: action.payload };
		case INTEGRATION_STATUSES_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}