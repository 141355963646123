import { Container, Row, Col, Button } from "react-bootstrap";

export default function TeamMembersDelete(props) {
	return (
		<Container fluid className="px-0">
			<Row className="m-0">
				<Col className="p-3">
					<h3>
						{props.config.wording.text_1}
					</h3>
					<p>
						{props.config.wording.text_2}
						<span className="SFProDisplay-Bold">{props.name}</span>
						{props.config.wording.text_3}
					</p>
				</Col>
			</Row>
			<Row className="d-flex justify-content-end border-top border-1 border-secondary p-3 m-0">
				<Col xs="auto" className="px-2">
					<Button variant="secondary" className="rounded-5" onClick={props.onCancel}>
						Cancel
					</Button>
				</Col>
				<Col xs="auto" className="px-2">
					<Button variant="dark-red" className="rounded-5" onClick={() => props.onSubmit(props.id)}>
						Delete
					</Button>
				</Col>
			</Row>
		</Container>
	);
}