import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Badge } from "react-bootstrap";

import { getAppSettingsData } from "../actions/appSettingsAction";
import { getIntegrationStatusesData } from "../actions/integrationStatuses";

import check from "../assets/images/check.svg";
import uncheck from "../assets/images/uncheck.svg";

export default function SetupInfo(props) {

	const dispatch = useDispatch();

	const userInfoStore = useSelector(state => state.userInfo);
	const appSettingsStore = useSelector(state => state.appSettings);
	const statusesStore = useSelector(state => state.integrationStatuses);

	const [dataReady, setDataReady] = useState(false);

	const _user_token = localStorage.getItem("userToken");

	const statusType = {
		"-2": "danger",
		"-1": "info",
		"0": "warning",
		"1": "success"
	}

	const statusColor = {
		"-2": "text-dark-red",
		"-1": "text-primary",
		"0": "text-dark-yellow",
		"1": "text-dark-green"
	}

	const updateStatus = () => {
		dispatch(getAppSettingsData(`/api/v1/frontend/applications/show`, { _user_token, application_id: userInfoStore.data.selected.application }));
	}

	useEffect(() => {
		if (!appSettingsStore.loaded) updateStatus();
	}, [appSettingsStore.loaded])

	useEffect(() => {
		if (!statusesStore.loaded)
			dispatch(getIntegrationStatusesData(`/api/v1/frontend/integration_statuses/list`));
	}, [statusesStore.loaded])

	useEffect(() => {
		if (appSettingsStore.loaded && statusesStore.loaded) setDataReady(true);
	}, [appSettingsStore.loaded, statusesStore.loaded])

	useEffect(() => {
		if (appSettingsStore.loaded) updateStatus();
	}, [userInfoStore.data.selected.application])

	return (
		<Container fluid className="px-0">

			<Row className="pt-3 m-0">
				<Col className="px-0">
					<h2 className="SFProDisplay-Semibold m-0">
						{props.config.wording.text_1}
					</h2>
				</Col>
				<Col className="text-end px-0">
				</Col>
			</Row>

			{dataReady && appSettingsStore.data.integration_statuses.map((integration, i) => (
				<Row className="pt-3 m-0" key={i}>
					<Col xs="auto" className="ps-0 pe-2">
						<img src={integration.status === 1 ? check : uncheck} className="img-fluid" alt="check" />
					</Col>
					<Col className="px-0">
						<Link to={`/settings?activeTab=${integration.code}`} className="text-decoration-none text-black">
							{integration.name}
						</Link>
					</Col>
					<Col xs="auto" className="px-0">
						<Link to={`/settings?activeTab=${integration.code}`} className="text-decoration-none text-black">
							<Badge bg={statusType[integration.status]} className={`${statusColor[integration.status]} fw-normal py-2 px-3 rounded-10`}>
								{statusesStore.data.find(x => x.id === integration.status).name}
							</Badge>
						</Link>
					</Col>
				</Row>
			))}

			{/* <Col className="p-3 ps-4">
					<Row className="pt-3 m-0">
						<Col className="px-0">
							<h2 className="SFProDisplay-Semibold m-0">
								Get Started with OpenScale! Connect via App Store, API or Integrate SDK
							</h2>
						</Col>
					</Row>

					<Row className="pt-3 m-0">
						<Col className="px-0">
							<p className="m-0">
								Get free access to charts and analytics
							</p>
						</Col>
					</Row>

					<Row className="m-0">
						<Col className="px-0">
							<Row className="pt-3 m-0">
								<Col className="px-0">
									<Badge bg="info" className="text-primary fw-normal py-2 px-3 rounded-10">
										No code
									</Badge>
								</Col>
							</Row>

							<Row className="pt-3 m-0">
								<Col className="px-0">
									<Button variant="link" className="SFProDisplay-Semibold text-primary text-decoration-none px-0">
										Start
									</Button>
								</Col>
							</Row>
						</Col>
						{
							showStatuses
								?
								<Col className="px-0">
									<img src={devices} className="img-fluid" alt="devices" />
								</Col>
								:
								<></>
						}
					</Row>
				</Col> */}

			{/* {!showStatuses &&
					<Col xs="auto" className="pt-5 px-0">
						<img src={devices} className="img-fluid" alt="devices" />
					</Col>
				} */}

		</Container >
	);
}