import { ToastContainer, Toast } from "react-bootstrap"

export default function Notification(props) {

	return (
		<ToastContainer className="position-fixed p-3" position="top-end" style={{ zIndex: 9999999 }}>
			<Toast
				bg={props.notification.title === "Error" ? "danger" : "success"}
				onClose={() => props.handleNotification({ show: false, title: "", time: "", text: "" })}
				show={props.notification.show}
				delay={5000}
				autohide
			>
				<Toast.Header>
					<strong className="me-auto">
						{props.notification.title ? props.notification.title : ""}
					</strong>
					<small>
						{props.notification.time ? props.notification.time : "Now"}
					</small>
				</Toast.Header>
				<Toast.Body className={props.notification.title === "Error" ? "text-dark-red" : "text-dark-green"}>
					{props.notification.text}
				</Toast.Body>
			</Toast>
		</ToastContainer>
	)
}