export const PLATFORMS_DATA = "PLATFORMS_DATA";
export const PLATFORMS_LOADED = "PLATFORMS_LOADED";
export const PLATFORMS_ERROR = "PLATFORMS_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function platformsReducer(state = defaultState, action) {
	switch (action.type) {
		case PLATFORMS_DATA:
			return { ...state, data: action.payload };
		case PLATFORMS_LOADED:
			return { ...state, loaded: action.payload };
		case PLATFORMS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}