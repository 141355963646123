import { Modal } from "react-bootstrap";

export default function CustomModal(props) {

	return (
		<Modal
			show={props.show}
			fullscreen={props.fullscreen}
			onHide={() => props.handleModal(false)}
			centered
			className="rounded-10"
		>
			{props.showHeader &&
				<Modal.Header className="border-0" closeButton={props.closeButton} />
			}
			<Modal.Body className="p-0">
				{props.children}
			</Modal.Body>
			{props.footer &&
				<Modal.Footer className="p-0">
					{props.footer}
				</Modal.Footer>
			}
		</Modal>
	);
}