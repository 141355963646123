export const USERINFO_DATA = "USERINFO_DATA";
export const USERINFO_LOADED = "USERINFO_LOADED";
export const USERINFO_ERROR = "USERINFO_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function userInfoReducer(state = defaultState, action) {
	switch (action.type) {
		case USERINFO_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case USERINFO_LOADED:
			return { ...state, loaded: action.payload };
		case USERINFO_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}