import { INTEGRATION_STATUSES_DATA, INTEGRATION_STATUSES_LOADED, INTEGRATION_STATUSES_ERROR } from "../reducers/integrationStatusesReducer";

export const getIntegrationStatusesData = (url) => (dispatch) => {
	fetch(url)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: INTEGRATION_STATUSES_DATA, payload: data._data.integration_statuses });
				dispatch({ type: INTEGRATION_STATUSES_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: INTEGRATION_STATUSES_ERROR, payload: err.message });
		});
}