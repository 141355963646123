import { Container, Row, Col, Table, Badge } from "react-bootstrap";

export default function TableChart(props) {

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		}).format(timestamp * 1000);
	}

	return (
		<Container fluid className="border-bottom border-end border-secondary p-3">

			<Row className="d-flex justify-content-between m-0">
				<Col className="d-flex align-items-center px-0">
					<span className="text-dark pe-2">
						{props.name}
					</span>
					{props.dynamics &&
						<Badge
							bg={props.dynamics > 0 ? "success" : "danger"}
							className={`${props.dynamics > 0 ? "text-dark-green" : "text-dark-red"} SFProDisplay-Medium fw-normal`}>
							{props.dynamics > 0 && "+"}{props.dynamics} %
						</Badge>
					}
				</Col>
			</Row>
			<Row className="d-flex justify-content-between m-0">
				<Col className="px-0">
					<p className="SFProDisplay-Medium m-0">{props.currency} {props.sum}</p>
				</Col>
			</Row>

			<Row className="pt-2 pb-3 m-0">
				<Col className="px-0">
					<Table responsive bordered className="border-secondary">

						<tbody className="m-0">
							{props.rows.map((row, i) => (
								<tr key={i}>
									<td className="position-sticky start-0 bg-white custom-cell">
										{row}
									</td>
									{props.data.map((val, index) => (
										<td key={index} className="text-end custom-cell">
											{row === "date" ? timestampToDate(val[row]) : val[row]}
										</td>
									))}
								</tr>
							))}
						</tbody>

					</Table>
				</Col>
			</Row>
		</Container>
	)
}