import { forwardRef } from "react";
import { Dropdown, Button } from "react-bootstrap";

import styles from "./CustomDropdown.module.scss";

export default function CustomDropdown(props) {

	const CustomToggle = forwardRef(({ children, onClick }, ref) => (
		<Button
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className={`${styles.toggle} bg-transparent border-0 p-0`}
		>
			{children}
		</Button>
	));

	return (
		<Dropdown align={props.align} onClick={(e) => e.stopPropagation()}>
			{props.noArrow
				?
				<Dropdown.Toggle as={CustomToggle}>
					{props.buttonText}
				</Dropdown.Toggle>
				:
				<Dropdown.Toggle variant="secondary" className="rounded-5 px-3">
					{props.buttonText}
				</Dropdown.Toggle>
			}
			<Dropdown.Menu className="rounded-10 border-0 shadow py-0">
				{props.children}
				<Dropdown.Item className="p-0">
					{props.dropdownItem}
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}