import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserInfoData } from "../actions/userInfoActions.js";
import Loader from "./UI/Loader/index.jsx";

export default function Authentification({ children }) {

	const dispatch = useDispatch();
	const userInfo = useSelector(state => state.userInfo);

	useEffect(() => {
		if (!userInfo.loaded)
			dispatch(getUserInfoData(`/api/v1/frontend/me/info`, { _user_token: localStorage.getItem("userToken") }));
	}, [userInfo.loaded])

	return userInfo.loaded ? children : <Loader />;
}